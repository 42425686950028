<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters justify="space-between">
          <h3 class="font-weight-bold">{{$t('view.favorites.title')}}</h3>
          <v-btn icon @click="toggleCreateFavoriteDialog(true)">
            <v-icon color="primary">fa fa-plus</v-icon>
          </v-btn>
        </v-row>

      </v-col>

      <v-col cols="12" v-if="!favoritesLoaded">
        <Skeleton
          :occurrence="3"
          type="list-item" />
      </v-col>

      <v-col cols="12" v-else class="mt-3">
        <p v-if="favorites.length === 0">{{$t('view.favorites.no-result')}}</p>
        <v-card class="my-2" style="border-radius: 10px !important;">

          <!-- Home/Work list -->
          <v-list>
            <template v-for="(el) of favorites">
              <v-list-item v-if="['HOME', 'WORK'].includes(el.type)">
                <v-list-item-icon>
                  <v-icon v-if="el.type === 'HOME'" color="primary" size="20">far fa-home</v-icon>
                  <v-icon v-else color="primary" size="25">far fa-briefcase</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="cursor: pointer !important;" @click="goTo(el)">{{el.address}}</v-list-item-title>
                <v-list-item-action>
                  <v-menu
                    :nudge-width="200"
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon elevation="2" class="mx-1" v-bind="attrs" v-on="on">
                        <v-icon>far fa-ellipsis-h</v-icon>
                      </v-btn>
                    </template>

                    <v-card style="border-radius: 10px !important;">
                      <v-list>
                        <v-list-item @click="itineraryFrom(el)">{{$t('view.history.buttons.itineraryFrom')}}</v-list-item>
                        <v-list-item @click="itineraryTo(el)">{{$t('view.history.buttons.itineraryTo')}}</v-list-item>
                        <v-list-item @click="deleteSearchHistory(el._id)" style="color: red !important;">{{$t('view.history.buttons.delete')}}</v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>

          <v-divider v-if="favorites.some((el) => ['HOME', 'WORK'].includes(el))"/>

          <v-list>
            <template v-for="(el) of favorites">
              <v-list-item v-if="!['HOME', 'WORK'].includes(el.type)">
                <v-list-item-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{el.address}}</v-list-item-title>
                  <v-list-item-subtitle>{{el.label}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu
                    :nudge-width="200"
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon elevation="2" class="mx-1" v-bind="attrs" v-on="on">
                        <v-icon>far fa-ellipsis-h</v-icon>
                      </v-btn>
                    </template>

                    <v-card style="border-radius: 10px !important;">
                      <v-list>
                        <v-list-item @click="itineraryFrom(el)">{{$t('view.favorites.buttons.itineraryFrom')}}</v-list-item>
                        <v-list-item @click="itineraryTo(el)">{{$t('view.favorites.buttons.itineraryTo')}}</v-list-item>
                        <v-list-item @click="deleteFavorite(el._id)" style="color: red !important;">{{$t('view.favorites.buttons.delete')}}</v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create favorite dialog -->
    <CreateFavoriteDialog
      v-if="showCreateFavoriteDialog"
      :coordinates="$store.getters['coords']"
      :value="showCreateFavoriteDialog"
      @close="(needRefresh) => toggleCreateFavoriteDialog(false, needRefresh)" />

  </v-container>
</template>

<script>

export default {
  name: "MyFavorites",

  components: {
    Skeleton: () => import("@/components/Common/Skeleton"),
    CreateFavoriteDialog: () => import("@/components/Account/Dialogs/CreateFavoriteDialog"),
  },

  data() {
    return {

      favoritesLoaded: false,
      favorites: [],

      showCreateFavoriteDialog: false
    }
  },

  created() {
    this.getFavorites();
    this.$store.commit("pageHead/setTitle", this.$t('view.favorites.pageTitle'))
  },

  methods: {
    getFavorites() {
      this.favoritesLoaded = false;
      this.$http
      .get(`/map/users/${this.$session.get('id')}/favorites?limit=20`, {
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      })
      .then((res) => {
        this.favorites = res.data.items;
      })
      .catch(err => {
        this.$store.commit("alert/showError", this.$t('view.favorites.errors.NOT_LOADED'));
      })
      .finally(() => {
        this.favoritesLoaded = true;
      });
    },

    toggleCreateFavoriteDialog(val, needRefresh = false) {
      if (needRefresh)
        this.getFavorites();
      this.showCreateFavoriteDialog = val;

    },

    deleteFavorite(id) {
      this.$http
        .delete(`/map/users/${this.$session.get('id')}/favorites/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          }
        })
        .then((res) => {
          this.$store.commit("alert/showSuccess", this.$t('view.favorites.success-deleted'));
          this.getFavorites();
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
    },

    goTo(data) {
      if (data && data.coordinates.lat) {
        this.$router.replace({
          query: {...this.$route.query, coords: `${data.coordinates.lat},${data.coordinates.lng}`}
        })
      }
    },

    itineraryFrom(data) {
      this.$router.replace({name: "Itinerary", query:  {...this.$route.query, from: `${data.coordinates.lat},${data.coordinates.lng}`, fromLabel: data.address}});
    },

    itineraryTo(data) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, fromLabel: "Ma position", from: `${position.coords.latitude},${position.coords.longitude}`, toLabel: data.address, to: `${data.coordinates.lat},${data.coordinates.lng}`}});
      }, (error) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, toLabel: data.address, to: `${data.coordinates.lat},${data.coordinates.lng}`}});
      });
    },
  }
}
</script>

<style scoped>

</style>